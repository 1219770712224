<template>
    <div>
        <div class="head">
            <div class="title"><i class="el-icon-edit-outline" style="color: #1ea0fa;"></i> 创建模板</div>
            <div class="back"><el-button style="color: #1ea0fa;" @click="$router.push('/perform/mould')">返回</el-button>
            </div>
        </div>
        <div class="perform-center" v-show="active == 1">
            <div class="content">
                <el-divider>请输入模板名称</el-divider>
                <div class="">
                    <el-input v-model="mouldTit" placeholder="请填写"></el-input>
                </div>
            </div>
        </div>
        <div class="perform-center2" v-show="active == 2">
            <div class="top">
                <div class="title">{{ mouldTit }}</div>
                <el-input v-model="illustrate" type="textarea" :rows="5" placeholder="请输入考核说明介绍"></el-input>
            </div>
            <el-divider></el-divider>
            <div class="box">
                <div class="item" v-for="(item, index) in list" :key="index">
                    <div class="list">
                        <!-- <div class="cut" @click="changeChild">
                            <i class="el-icon-arrow-right" v-show="!show"></i>
                            <i class="el-icon-arrow-down" v-show="show"></i>
                        </div> -->
                        <div class="titBox">
                            <div class="tit">{{ item.Parent_Title }}</div>
                            <div class="set">
                                <span>[分值：{{ item.Parent_Score_Max }}]</span>

                                <el-dropdown trigger="click">
                                    <span class="el-dropdown-link" style="color: #409eff;">
                                        <i class="el-icon-edit"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item @click.native="moveUp(index)">上移</el-dropdown-item>
                                        <el-dropdown-item @click.native="moveDown(index)">下移</el-dropdown-item>
                                        <el-dropdown-item @click.native="edit(index)">编辑考核</el-dropdown-item>
                                        <el-dropdown-item @click.native="del(index)">删除</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                        <div class="score" v-if="item.Is_SetScore">
                            <span>评分</span>
                            <el-input style="width: 30%;"></el-input>
                        </div>
                    </div>
                    <div class="child">
                        <div class="childItem" v-for="(child, ind) in item.Children" :key="ind">
                            <div class="childTit">
                                <div class="tit"> {{ child.Child_Title }}</div>
                                <div class="set">
                                    <span>[分值：{{ child.Child_Score_Max }}]</span>
                                    <el-dropdown trigger="click">
                                        <span class="el-dropdown-link" style="color: #409eff;">
                                            <i class="el-icon-edit"></i>
                                        </span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item @click.native="moveChildUp(index, ind)">
                                                上移
                                            </el-dropdown-item>
                                            <el-dropdown-item @click.native="moveChildDown(index, ind)">
                                                下移
                                            </el-dropdown-item>
                                            <el-dropdown-item @click.native="editSmall(index, ind)">
                                                编辑子考核
                                            </el-dropdown-item>
                                            <el-dropdown-item
                                                @click.native="deleteChild(index, ind)">删除</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                            </div>
                            <div class="score">
                                <span>评分</span>
                                <el-input style="width: 30%;"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="childAdd" v-if="!item.Is_SetScore" @click="showChild(index)">新增子考核</div>
                </div>
            </div>
            <div class="add" @click="showBigAdd">新增考核</div>
        </div>
        <div class="bottom">
            <el-button type="primary" @click="next">下一步</el-button>
        </div>
        <!--  -->
        <el-dialog title="新增考核" width="600px" :visible.sync="visible" :close-on-click-modal="false">
            <el-form label-width="100px">
                <el-form-item label="考核名称：">
                    <el-input v-model="bigTit" type="textarea" :rows="3" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="是否有子考核:">
                    <el-select v-model="bigIsScore">
                        <el-option label="是" :value="0"></el-option>
                        <el-option label="否" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="分值：" v-if="bigIsScore">
                    <el-input v-model="bigScore" style="width: 30%; margin-right: 10px;"></el-input><span>分</span>
                </el-form-item>
            </el-form>
            <div class="foo" style="text-align: center;">
                <el-button @click="closeBigAdd">取消</el-button>
                <el-button v-if="!editVisible" type="primary" @click="addBig">确定</el-button>
                <el-button v-if="editVisible" type="primary" @click="editBig">确定</el-button>
            </div>
        </el-dialog>
        <!--  -->
        <el-dialog title="新增子考核" width="600px" :visible.sync="visibleChild" :close-on-click-modal="false">
            <el-form label-width="100px">
                <el-form-item label="子考核名称：">
                    <el-input v-model="smallTit" type="textarea" :rows="3" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="分值：">
                    <el-input v-model="smallScore" style="width: 30%; margin-right: 10px;"></el-input><span>分</span>
                </el-form-item>
            </el-form>
            <div class="foo" style="text-align: center;">
                <el-button @click="closeChild">取消</el-button>
                <el-button v-if="!editVisible" type="primary" @click="addChild">确定</el-button>
                <el-button v-if="editVisible" type="primary" @click="editChild">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            active: 1,

            mouldTit: '',
            illustrate: '',

            bigTit: '',
            bigScore: 0,
            bigIndex: 0,
            bigIsScore: 0,

            smallTit: '',
            smallScore: 0,
            smallIsScore: 0,

            show: true,

            list: [],

            visible: false,

            visibleChild: false,
            editVisible: false,
            editIndex: 0,
            editNum: 0,

            ID: 0
        }
    },
    created() {
        if (this.$route.query.id) {
            let self = this
            this.whale.remote.getResult({
                url: "/api/School/PFM/PfmTempalteSetting/Get?Id=" + self.$route.query.id,
                completed: function (its) {
                    self.mouldTit = its.DATA.Template_Name
                    self.illustrate = its.DATA.Assessment_Description
                    self.list = its.DATA.Details
                    self.ID = its.DATA.ID
                }
            })
        }

    },
    mounted() {

    },
    methods: {
        next() {
            if (this.active == 2) {
                if (this.ID == 0) {
                    this.submit()
                } else {
                    this.update()
                }
            } else {
                if (this.mouldTit == '') {
                    this.$message.warning('请填写模板名称')
                    return
                } else {
                    this.active++
                }
            }
        },
        changeChild() {
            this.show = !this.show
        },
        // 
        moveUp(index) {
            if (index == 0) {
                this.$message.warning('已经是第一个，无法上移')
                return
            } else {
                this.changeSort(index, -1)
            }
        },
        moveDown(index) {
            if (index == this.list.length - 1) {
                this.$message.warning('已经是最后一个，无法下移')
                return
            } else {
                this.changeSort(index, 1)
            }
        },
        changeSort(index, isUp) {
            let lists = JSON.parse(JSON.stringify(this.list));
            [lists[index + isUp], lists[index]] = [lists[index], lists[index + isUp]];
            this.list = lists
        },
        edit(index) {
            this.visible = true
            this.editVisible = true
            this.editIndex = index
            this.bigTit = this.list[index].Parent_Title
            this.bigScore = this.list[index].Parent_Score_Max
            this.bigIsScore = this.list[index].Is_SetScore
        },
        del(index) {
            this.list.splice(index, 1)
        },
        showBigAdd() {
            this.visible = true
            this.editVisible = false
            this.bigTit = ''
            this.bigScore = 0
            this.bigIsScore = 0
        },
        addBig() {
            if (this.bigIsScore) {
                let obj = {
                    Parent_Title: this.bigTit,
                    Parent_Score_Max: Number(this.bigScore),
                    Parent_Sort: this.list.length + 1,
                    Is_SetScore: this.bigIsScore,
                    Children: []
                }
                this.list.push(obj)
            } else {
                let obj = {
                    Parent_Title: this.bigTit,
                    Parent_Score_Max: 0,
                    Parent_Sort: this.list.length + 1,
                    Is_SetScore: this.bigIsScore,
                    Children: []
                }
                this.list.push(obj)
            }
            this.closeBigAdd()
        },
        editBig() {
            this.list[this.editIndex].Parent_Title = this.bigTit
            this.list[this.editIndex].Parent_Score_Max = this.bigScore
            this.closeBigAdd()
        },
        closeBigAdd() {
            this.visible = false
        },
        // 
        moveChildUp(index, chind) {
            if (chind == 0) {
                this.$message.warning('已经是第一个，无法上移')
                return
            } else {
                this.changeChildSort(index, chind, -1)
            }
        },
        moveChildDown(index, chind) {
            if (chind == this.list[index].Children.length - 1) {
                this.$message.warning('已经是最后一个，无法下移')
                return
            } else {
                this.changeChildSort(index, chind, 1)
            }
        },
        changeChildSort(index, chind, isUp) {
            let lists = JSON.parse(JSON.stringify(this.list[index].Children));
            [lists[chind + isUp], lists[chind]] = [lists[chind], lists[chind + isUp]];
            this.list[index].Children = lists
        },
        editSmall(index, num) {
            this.visibleChild = true
            this.editVisible = true
            this.editIndex = index
            this.editNum = num
            this.smallTit = this.list[index].Children[num].Child_Title
            this.smallScore = this.list[index].Children[num].Child_Score_Max
        },
        deleteChild(index, chind) {
            this.list[index].Parent_Score_Max = this.list[index].Parent_Score_Max - this.list[index].Children[chind].Child_Score_Max
            this.list[index].Children.splice(chind, 1)
        },
        showChild(index) {
            this.visibleChild = true
            this.editVisible = false
            this.bigIndex = index
            this.smallTit = ''
            this.smallScore = 0
        },
        addChild() {
            console.log('1', this.smallScore)
            console.log('2', this.list[this.bigIndex].Parent_Score_Max)
            // if (this.smallScore <= this.list[this.bigIndex].Parent_Score_Max) {
            // if (this.list[this.bigIndex].Children.length == 0) {
            let obj = {
                Child_Title: this.smallTit,
                Child_Score_Max: Number(this.smallScore),
                Child_Sort: this.list[this.bigIndex].Children.length + 1,
                Is_SetScore: 1
            }
            this.list[this.bigIndex].Parent_Score_Max = this.list[this.bigIndex].Parent_Score_Max + Number(this.smallScore)
            this.list[this.bigIndex].Children.push(obj)
            this.closeChild()
            // } else {
            //     let arr = this.list[this.bigIndex].Children
            //     var num = 0
            //     arr.map(i => {
            //         console.log(i)
            //         num += Number(i.Child_Score_Max)
            //     })
            //     console.log('num', num)
            //     if (num + Number(this.smallScore) <= this.list[this.bigIndex].Parent_Score_Max) {
            //         let obj = {
            //             Child_Title: this.smallTit,
            //             Child_Score_Max: this.smallScore,
            //             Child_Sort: this.list[this.bigIndex].Children.length + 1,
            //             Is_SetScore: 1
            //         }
            //         this.list[this.bigIndex].Children.push(obj)
            //         this.closeChild()
            //     } else {
            //         this.$message.warning('小题分值不能超过大题分值！')
            //     }
            // }

            // } else {
            //     this.$message.warning('小题分值不能超过大题分值！')
            // }
        },
        editChild() {
            this.visibleChild = false
            this.editVisible = false
            this.list[this.editIndex].Children[this.editNum].Child_Title = this.smallTit
            this.list[this.editIndex].Children[this.editNum].Child_Score_Max = this.smallScore

            this.list[this.editIndex].Parent_Score_Max = 0
            this.list[this.editIndex].Children.forEach(i => {
                this.list[this.editIndex].Parent_Score_Max += Number(i.Child_Score_Max)
            })
        },
        closeChild() {
            this.visibleChild = false
        },
        // 
        submit() {
            if (this.list.length == 0) {
                this.$message.warning('请添加题目')
                return
            }
            let data = {
                Template_Name: this.mouldTit,
                Assessment_Description: this.illustrate,
                Details: this.list
            }
            console.log(data)
            let self = this
            this.whale.remote.getResult({
                url: "/api/School/PFM/PfmTempalteSetting/CreateNew",
                data: data,
                completed: function () {
                    // self.$message.success('创建成功！')
                    self.$router.push({ path: '/perform/back', query: { tit: self.mouldTit } })
                }
            })
        },
        update() {
            if (this.list.length == 0) {
                this.$message.warning('请添加题目')
                return
            }
            let data = {
                ID: this.ID,
                Template_Name: this.mouldTit,
                Assessment_Description: this.illustrate,
                Details: this.list
            }
            console.log(data)
            let self = this
            this.whale.remote.getResult({
                url: "/api/School/PFM/PfmTempalteSetting/Update",
                data: data,
                completed: function () {
                    // self.$message.success('创建成功！')
                    self.$router.push({ path: '/perform/back', query: { tit: self.mouldTit } })
                }
            })
        }
    },
}
</script>
<style>
.perform-center {
    padding: 20px 40px;
}

.head {
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.perform-center .content {
    width: 800px;
    background: #fff;
    border: 1px solid #f4f4f4;
    height: 500px;
    margin: 0 auto;
    padding-top: 100px;
}

.bottom {
    position: fixed;
    bottom: 0px;
    width: 87%;
    background: #fff;
    text-align: center;
    padding: 20px 0;
    z-index: 9;
}

.perform-center2 {
    width: 800px;
    height: 700px;
    overflow-y: auto;
    background: #fff;
    margin: 0px auto 0;
    padding: 20px 40px;
}

.perform-center2 .top .title {
    color: #1ea0fa;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
}

.perform-center2 .add {
    text-align: center;
    color: #65adff;
    border: 1px solid #bfdfff;
    background: #f5fcff;
    padding: 10px 0;
    cursor: default;
}

.perform-center2 .box {}

.perform-center2 .box .item .list {
    position: relative;
}

.perform-center2 .box .item .list .cut {
    position: absolute;
    left: -20px;
    top: 20px;
    display: inline-block;
    /* background: #43aeef; */
    width: 10px;
    height: 10px;
}

.perform-center2 .box .titBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fafafa;
    color: #111111;
    padding: 10px 10px;
    border: 1px solid #ebeef5;
    font-weight: bold;
    font-size: 15px;
}

.perform-center2 .box .item .list .score {
    border: 1px solid #f0f0f0;
    padding: 10px 10px;
    width: 90%;
    margin: 10px auto;
}

.perform-center2 .box .titBox .set {
    color: #81b9fe;
}

.perform-center2 .box .titBox .set span {
    margin: 0 5px;
}

.perform-center2 .box .childAdd {
    width: 90%;
    border: 1px solid #ebeef5;
    text-align: center;
    color: #65adff;
    padding: 10px 0;
    cursor: default;
    margin: 10px auto;
}

.perform-center2 .box .childItem {
    width: 90%;
    margin: 0 auto;
}

.perform-center2 .box .childItem .childTit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    font-size: 14px;
}

.perform-center2 .box .childItem .score {
    border: 1px solid #f0f0f0;
    padding: 10px 10px;
}
</style>